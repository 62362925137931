import React, { useContext, useState } from "react";
import { ApiContext } from "../ApiContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

function Register() {
  const navigate = useNavigate();

  const { ApiCall, set_user } = useContext(ApiContext);
  const { value } = useParams();
  const [email, set_email] = useState(value);
  const [password, set_password] = useState("");
  const [password_confirm, set_password_confirm] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    const body = {
      email: email,
      password: password,
    };
    if (password !== password_confirm) {
      toast.error("Salasanat eivät täsmää")
      return;
    }
    ApiCall("POST", "user/register", body)
      .then((res) => {
        console.log(res);
        document.cookie = "token=" + res.token;
        set_user(body.email);
        navigate("/");
      })
      .catch((err) => {
        console.error(`Error registering ${err}`);
        if (err.response.data.error === "email") {
          toast.error("Sähköpostiosoitteesi on jo rekisteröity");
        } else {
          toast.error("Jotain odottamatonta meni pieleen");
        }
      });
  }

  return (

    <div className="main-content">
      <Toaster />
      <div className="input-container">
        <h1>Tervetuloa käyttämään Kajo Spotti-palvelua!</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            autoFocus={true}
            required={true}
            placeholder="Syötä sähköpostiosoitteesi"
            value={email}
            onChange={(e) => {
              set_email(e.target.value);
            }}
          ></input>
          <input
            type="password"
            required={true}
            minLength={8}
            placeholder="Syötä salasana"
            id="password"
            onChange={(e) => {
              set_password(e.target.value);
            }}
          ></input>
          <input
            type={"password"}
            required={true}
            minLength={8}
            placeholder="Syötä salasana uudelleen"
            onChange={(e) => {
              set_password_confirm(e.target.value);
            }}
          ></input>
          <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "1em" }}>
            <input type={"checkbox"} required={true}></input>
            <label style={{ marginBottom: "0px" }}>
              Hyväksyn <Link className="link" to="/tos" style={{ margin: "0px" }}>
                Käyttöehdot
              </Link>
            </label>
          </div>
          <input type="submit" value="Rekisteröidy" className="basic-button" />
        </form>
      </div>
    </div>
  );
}

export default Register;
