import React, { useContext, useState } from "react";
import { ApiContext } from "../ApiContext";
import { Toaster, toast } from "react-hot-toast";

function ChangePassword() {
    const { ApiCall, user } = useContext(ApiContext);
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    function handleSubmit(event) {
        event.preventDefault()
        const body = {
            password: password,
            newPassword: newPassword
        }
        if (newPassword !== newPasswordConfirm) {
            toast.error("Salasanat eivät täsmää")
            return;
        }
        ApiCall("POST", "user/changePassword", body)
            .then((res) => {
                console.log(res);
                toast.success("Salasana vaihdettu")
                setNewPassword("")
                setNewPasswordConfirm("")
            })
            .catch((err) => {
                console.error(`Error changing password ${err}`)
                toast.error("Tarkista salasana");
            })
    }

    return (<>
        <Toaster />
        <div className="input-container">
            <h3>Salasanan vaihto</h3>
            <form onSubmit={handleSubmit}>
                <label>Sähköpostiosoitteesi</label>
                <input
                    type="email"
                    autoFocus={false}
                    required={false}
                    value={user}
                    readOnly={true}
                ></input>
                <label>Syötä vanha salasanasi</label>
                <input
                    type="password"
                    required={true}
                    minLength={8}
                    placeholder="Salasana"
                    autoComplete="current-password"
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                ></input>
                <label>Syötä uusi salasanasi</label>
                <input
                    type={"password"}
                    required={true}
                    value={newPassword}
                    minLength={8}
                    placeholder="Uusi salasana"
                    autoComplete="new-password"
                    onChange={(e) => {
                        setNewPassword(e.target.value);
                    }}
                ></input>
                <label>Syötä uusi salasanasi uudestaan</label>
                <input
                    type={"password"}
                    required={true}
                    value={newPasswordConfirm}
                    minLength={8}
                    placeholder="Uusi salasana"
                    autoComplete="new-password"
                    onChange={(e) => {
                        setNewPasswordConfirm(e.target.value);
                    }}
                ></input>
                <input type="submit" value="Vaihda salasana" className="basic-button" />
            </form>
        </div>
    </>
    );
}

export default ChangePassword;
