import React, { useContext, useEffect, useState } from "react";
import placeholderPng from "../assets/images/kajokaara.jpeg";
import placeholder from "../assets/images/kajokaara.webp";
import { ApiContext } from "../ApiContext";
import { useNavigate } from "react-router-dom";
import DeviceStatus from "./DeviceStatus";
import { motion } from "framer-motion";
import { popupEasing } from "../utils/Easing";

function ListDevice({ device, index }) {
  const navigate = useNavigate()
  const [config, set_config] = useState()
  const { ApiCall } = useContext(ApiContext)
  const [imageReady, setImageReady] = useState(false)

  useEffect(() => {
    if (device.activeConfig) {
      console.log(`Fetching active config for ${device.mac}`);
      ApiCall("GET", "device/config/active/" + device.mac)
        .then((res) => {
          console.log(res);
          set_config(res);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.log(`No active config for ${device.mac}`);
    }
  }, [device, ApiCall]);

  const image =
    console.log(imageReady)
  if (!config) {
    return <></>
  }
  return (
    <motion.nav
      initial={{ translateY: 100, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
      transition={{ ease: popupEasing, duration: 0.3, delay: index * 0.1 }}>
      <div onClick={() => navigate("/device/" + device.mac)} className="device-list-item">
        <div className="device-list-image">
          <picture onLoad={() => setImageReady(true)}>
            <source srcSet={placeholder} />
            <img src={placeholderPng} style={{ height: "100%", width: "100%", objectFit: "cover" }} alt="Laitteen kuva" />
          </picture>
        </div>
        {imageReady && <div style={{ padding: "15px" }}>
          <h3 style={{ margin: "5px" }}>{device.displayName}</h3>
          {/* TODO: Does not handle resizing but who cares */}
          <DeviceStatus config={config} showTicks={false} />
        </div>}
      </div>
    </motion.nav>
  );
}

export default ListDevice;
