import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.svg";
import { ReactComponent as Gear } from "../assets/icons/gear.svg";
import { ReactComponent as Connection } from "../assets/icons/connection.svg";
import DeviceStatus from "./DeviceStatus";
import DeviceLog from "./DeviceLog";
import { ApiContext } from "../ApiContext";
import "./Device.css";
import { Toaster, toast } from "react-hot-toast";
import Loading from "./Loading";

function ConfirmEmail() {
    const { ApiCall } = useContext(ApiContext);

    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [confirmRequested, setConfirmRequested] = useState(false)
    const [result, setResult] = useState();


    useEffect(() => {
        if (token && ApiCall && !confirmRequested) {
            setConfirmRequested(true)
            ApiCall("POST", "user/confirm", {
                token: token
            })
                .then((res) => {
                    console.log(res);
                    toast.success("Sähköposti vahvistettu onnistuneesti")
                    setLoading(false)
                    setResult(true)
                })
                .catch((err) => {
                    console.error(err);
                    toast.error("Virhe sähköpostiosoitetta vahvistaessa")
                    setLoading(false)
                    setResult(false)
                });
        }
    }, [token, ApiCall]);

    return (
        <div className="main-content">
            <Toaster />
            {loading && <Loading />}
            {!loading && result && <p>Sähköposti vahvistettu. Voit nyt sulkea tämän sivun.</p>}
            {!loading && !result && <p>Sähköpostin vahvistus ei onnistunut. Yritä uudelleen tilaamalla uusi linkki. Linkki on voimassa lähetyshetkestä vuorokauden.</p>}
        </div>
    )
}

export default ConfirmEmail;
