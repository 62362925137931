import { Link, useNavigate } from "react-router-dom"
import { ReactComponent as Facebook } from "../assets/icons/socialmedia/facebook.svg";
import { ReactComponent as Instagram } from "../assets/icons/socialmedia/instagram.svg";
import { ReactComponent as Youtube } from "../assets/icons/socialmedia/youtube.svg";


export function Products() {
    return (
        <div className="main-content">
            <div style={{ maxWidth: "800px" }}>
                {getProducts()}
            </div>
        </div>
    )
}

function getProducts() {
    return <>
        <h2>Tuotteet ja palvelut</h2>
        <h3>Kajo Spotti</h3>
        <p>Optimoi pörssisähkön kulutuksesi ja säästä sähkölaskussa helposti ajastamalla kulutus halvimpaan aikaan. Lue lisää <Link to="/">täältä</Link>.</p>
        <p>Koekäyttöjakson aikana palvelu on käyttäjille ilmainen. Koekäyttöjakson päättymisestä ja palvelun siirtymisestä maksulliseksi tiedotetaan myöhemmin. Hinnasto tulee tarkentumaan myöhemmin, mutta alustava hinta on noin. 15 € vuodessa.</p>
    </>
}


export function About() {
    const navigate = useNavigate()

    function getSocials() {
        return <div style={{ display: "flex", marginLeft: "20px", marginRight: "20px", alignItems: "center" }}>
            <Link to="https://www.facebook.com/profile.php?id=61565477145485"><Facebook fill="#1877F2" style={{ height: "40px", marginRight: "20px" }} /></Link>
            <Link to="https://www.instagram.com/kajosolutions/"><Instagram style={{ height: "40px", marginRight: "20px" }} /></Link>
            <Link to="https://www.youtube.com/@KajoSolutionsOy"><Youtube style={{ height: "37px" }} /></Link>
        </div>
    }

    return (
        <div className="main-content">
            <div>
                <h1>Kajo Solutions Oy</h1>
                <p>Kajo Solutions Oy on suomalainen teknologiayritys, joka tarjoaa kuluttajille palveluita.</p>
                <h2>Ota yhteyttä</h2>
                <p>Vastaamme mieluisasti kysymyksiin ja muihin tiedusteluihin sähköpostiosoitteessa <a href="mailto:info@kajosolutions.com">info@kajosolutions.com</a>.</p>
                <h2>Seuraa meitä somessa</h2>
                {getSocials()}
                {getProducts()}
                <h2>Käyttöehdot</h2>
                <p>Voit lukea käyttöehtomme <Link to="/tos">täältä</Link>.</p>
                <h2>Rekisteri- ja tietosuojaseloste</h2>
                <p>Voi lukea rekisteri- ja tietosuojaselosteemme <Link to="/privacy">täältä</Link>.</p>
            </div>
        </div>
    );
}
