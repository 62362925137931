/* eslint-disable indent */
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "./env";
import { useNavigate } from "react-router-dom";

const VAT_PERCENTAGE = require("spot-automation-common").VAT_PERCENTAGE

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const navigate = useNavigate()
  const [user, set_user] = useState()
  const [prices, setPrices] = useState()
  const [todayPrices, setTodayPrices] = useState()

  async function logout(callApi = true) {
    if (callApi) {
      ApiCall("POST", "user/logout").then((res) => {
        console.log("Successfully logged out")
      }).catch((err) => {
        console.error(err)
      })
    }
    set_user(undefined);
    document.cookie = "token=; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    // Kek is this the onyl way to delete token?
  }
  useEffect(() => {
    let token = document.cookie.split("token=")[1];
    if (token !== undefined) {
      token = token.split(";")[0];
      ApiCall("GET", "user/profile").then((res) => {
        set_user(res.email);
      }).catch((err) => {
        console.error(err)
      })
    }
    ApiCall("GET", "prices")
      .then((res) => {
        const formatted = res.map((it) => {
          return {
            price: (it.price * (100 + VAT_PERCENTAGE)).toFixed(2),
            date: new Date(it.date),
          };
        });
        setPrices(formatted)
        setTodayPrices(formatted.slice(0, 24))
      })
      .catch((error) => {
        console.error(error);
      });
  }, [user]);

  const ApiCall = (method, url, body) => {
    //console.log(method, url, body);

    return new Promise((resolve, reject) => {
      let token = document.cookie.split("token=")[1];
      if (token !== undefined) {
        token = token.split(";")[0];
      }

      console.log("Fetching: " + BASE_URL + url);

      axios({
        method,
        url: BASE_URL + url,

        data: body,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 401) {
            //Something wrong with authentication, need to re-login
            // Don't call API in case it was the logout that failed to prevent loop
            logout(false);
            navigate("/");
          } else if (err.response && err.response.status === 429) {
            //FIXME: Cannot use toast for some reason
            // Does not work when <Toaster /> is added to root?
            alert("Liian monta pyyntöä osoitteestasi lyhyen ajan sisään. Odota hetki ja yritä uudestaan.")
          }
          // throws a runtime error if rejected and api call function does not have an error handler
          reject(err);
        });
    });
  };

  return (
    <ApiContext.Provider
      value={{
        ApiCall,
        user,
        set_user,
        prices,
        todayPrices,
        logout,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
