import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import Prices from "./views/Prices";
import ErrorPage from "./views/ErrorPage";
import Device from "./views/Device";
import Login from "./views/Login";
import Landing from "./views/Landing";
import DeviceList from "./views/DeviceList";
import Register from "./views/Register";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import TermsOfService from "./views/TermsOfService";
import { Root } from "./views/Root";
import ChangePassword from "./views/ChangePassword";
import { About, Products } from "./views/About";
import PrivacyPolicy from "./views/PrivacyPolicy";
import ConfirmEmail from "./views/ConfirmEmail";
import { Profile } from "./views/Profile";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
      {
        path: "/profile",
        element: <Profile />
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/login/:value",
        element: <Login />,
      },
      {
        path: "/forgot",
        element: <ForgotPassword />,
      },
      {
        path: "/forgot/:token",
        element: <ResetPassword />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/register/:value",
        element: <Register />,
      },
      {
        path: "/tos",
        element: <TermsOfService />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/prices",
        element: <Prices />,
      },
      {
        path: "/device",
        element: <DeviceList />,
      },
      {
        path: "device/:id",
        element: <Device />,
      },
      {
        path: "device/:id/:value",
        element: <Device />,
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/logout",
        element: <Navigate to="/" />
      },
      {
        path: "/products",
        element: <Products />
      },
      {
        path: "/confirm/:token",
        element: <ConfirmEmail />
      }
    ]
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
