import React, { useContext, useState } from "react";
import { ApiContext } from "../ApiContext";
import { motion, cubicBezier } from "framer-motion";
import "./Device.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ChargerSelection from "./ChargerSelection";
import { Toaster, toast } from "react-hot-toast";
import { popupEasing } from "../utils/Easing";

function SetupNewCharger({ endSetup }) {
    const { ApiCall } = useContext(ApiContext);
    const [deviceName, setDeviceName] = useState("");
    const [selectedCharger, setSelectedCharger] = useState()
    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();
        ApiCall("POST", "device/charger", {
            mac: crypto.randomUUID(),
            displayName: deviceName,
            chargerType: selectedCharger,
        })
            .then((res) => {
                console.log(res);
                navigate(`/device/${res.mac}/ocpp`)
            })
            .catch((err) => {
                console.error(err);
                toast.error("Tarkista nimi ja kokeile uudestaan");
            });
    }


    return (
        <motion.nav
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: "easeOut", duration: 0.2 }}
            className="device-setup-nav"
        >
            <Toaster />
            <div onClick={() => endSetup()} className="device-setup-container">
                <motion.nav
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ ease: popupEasing, duration: 0.2 }}
                    className="device-setup-nav"
                >
                    <div onClick={(e) => e.stopPropagation()} className="popup-container input-container">
                        <h2 style={{ marginTop: -5 }}>Lisää uusi latausasema</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="Lisää latausasemalle nimi"
                                required={true}
                                maxLength={256}
                                onChange={(e) => setDeviceName(e.target.value)}
                            />
                            <ChargerSelection selectedCharger={selectedCharger} setSelectedCharger={setSelectedCharger} />
                            <div style={{ marginTop: "1em", maxWidth: "480px" }}>
                                <p><b>Eikö latausasemaasi löydy listasta?</b></p>
                                <p> Valitse "Muu", ota yhteyttä sähköpostilla <Link to="mailto:info@kajosolutions.com?subject=Uuden latausaseman lisäys&Body=Hei,%0D%0Ahaluaisin tuen latausasemalleni">info@kajosolutions.com</Link> ja auta meitä lisäämään tuki latausasemallesi.</p>
                            </div>
                            <input
                                type="submit"
                                value="Lisää latausasema"
                                className="basic-button"
                            />

                        </form>
                    </div>
                </motion.nav>
            </div >
        </motion.nav >
    );
}

export default SetupNewCharger;
