import React, { useEffect, useState, useContext } from "react";
import { ApiContext } from "../ApiContext";
import PriceGraph from "./PriceGraph";
import "./Device.css";
import { ColorScheme } from "../utils/ColorScheme";
import { motion } from "framer-motion";

export default function DeviceStatus(props) {
  const { ApiCall, todayPrices } = useContext(ApiContext);

  const [dayPrices, setDayPrices] = useState();
  const [activity, setActivity] = useState();
  const [nextActivity, setNextActivity] = useState()
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (activity) {
      const now = new Date()
      now.setMinutes(0)
      now.setSeconds(0)
      now.setMilliseconds(0)
      var last = now
      var startOfNextActivity = undefined
      var endOfNextActivity = undefined
      console.log("Activity", activity)
      // Have to iterate activity becuase prices does not contain tomorrow data
      for (var i = 0; i < activity.length; i++) {
        const it = activity[i]
        if (it < now) {
          last = it
          continue
        }
        const secondsSinceLast = (it - last) / 1000
        if (secondsSinceLast <= 3600) {
          // Still the current active period
          last = it
          continue
        }
        else if (startOfNextActivity) {
          endOfNextActivity = new Date(last)
          endOfNextActivity.setTime(endOfNextActivity.getTime() + 60 * 60 * 1000)
          break;
        }
        else {
          startOfNextActivity = it
          last = it
        }
      }
      if (startOfNextActivity && !endOfNextActivity) {
        // Start set but no gap found, ended on last activity
        endOfNextActivity = new Date(activity[activity.length - 1])
        endOfNextActivity.setTime(endOfNextActivity.getTime() + 60 * 60 * 1000)
      }
      if (startOfNextActivity && endOfNextActivity) {
        console.log(`Setting next activity start ${startOfNextActivity}, end ${endOfNextActivity}`)
        setNextActivity({
          start: startOfNextActivity,
          end: endOfNextActivity
        })
      }
      else {
        console.log("Clearing next activity")
        setNextActivity(undefined)
      }
    }
  }, [activity])

  useEffect(() => {
    if (todayPrices && activity && dayPrices) {
      setAnimate(true)
    }
  }, [dayPrices, activity, todayPrices])

  function renderNextActivity() {
    if (props.config.mode !== "automatic") {
      return <div className="device-status-info">
        <p style={{ fontWeight: "900" }}>{props.config?.mode === "on" ? "Aina päällä" : "Pois päältä"}</p>
      </div>
    }
    else {
      var activityTxt = "Ei tiedossa"

      if (nextActivity && nextActivity.start.getDate() === new Date().getDate()) {
        activityTxt = `klo ${nextActivity.start.getHours()}-${nextActivity.end.getHours()}`
      }
      else if (nextActivity) {
        activityTxt = `Huomenna klo ${nextActivity.start.getHours()}-${nextActivity.end.getHours()}`
      }

      return <div className="device-status-info">
        <p>Seuraava aktiivisuus</p>
        <p style={{ fontWeight: "900" }}>{activityTxt}</p>
      </div>
    }
  }

  function isActive(val) {
    if (activity) {
      for (let i = 0; i < activity.length; i++) {
        if (val.getDate() === activity[i].getDate() && val.getHours() === activity[i].getHours()) {
          return true;
        }
      }
    }
    return false;
  }

  function isIndexDisabled(index) {
    if (props.config.mode !== "automatic") {
      return false;
    }
    if (props.config.firstHour > props.config.lastHour) {
      if (index % 24 < props.config.firstHour && index % 24 > props.config.lastHour) {
        return true;
      }
    } else {
      if (index % 24 < props.config.firstHour || index % 24 > props.config.lastHour) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    console.log(`Gettings devices status for  ${JSON.stringify(props.config)}`);
    if (isNaN(props.config.firstHour) || isNaN(props.config.lastHour) || isNaN(props.config.numberOfHours) || isNaN(props.config.maxPrice)) {
      console.log("Invalid config")
    }
    else {
      ApiCall("POST", "device/usage", props.config).then((res) => {
        let act = res.map((it) => new Date(it.date));
        act.sort((a, b) => a.getTime() - b.getTime());
        setActivity(act);
      }).catch((err) => {
        console.error(err)
      })
    }
  }, [props.config]);

  const now = new Date();
  useEffect(() => {
    if (todayPrices && !dayPrices) {
      let min = 99999;
      let max = 0;
      let current = 0;
      for (let i = 0; i < todayPrices.length; i++) {
        let price = parseFloat(todayPrices[i].price);
        if (price > max) {
          max = price;
        }
        if (price < min) {
          min = price;
        }
        if (todayPrices[i].date.getDate() === now.getDate() && todayPrices[i].date.getHours() === now.getHours()) {
          current = price;
        }
      }
      setDayPrices({ min: min, max: max, current: current });
    }
  }, [todayPrices]);
  const highlightedIndices = []
  const disabledIndices = []
  if (todayPrices) {
    todayPrices.map((v, i) => {
      if (isActive(v.date)) {
        highlightedIndices.push(i)
      }
      if (isIndexDisabled(i)) {
        disabledIndices.push(i)
      }
    })
  }

  if (!todayPrices || !activity || !dayPrices) {
    return <></>;
  } else {
    return (
      <motion.nav
        initial={{ opacity: 0 }}
        animate={{ opacity: animate ? 1 : 0 }}
        transition={{ ease: "linear", duration: 0.3, delay: 0.1 }}>
        <div className="device-status">
          <div className="device-graph-container">
            <div className="device-graph">
              <PriceGraph
                prices={todayPrices}
                showTicks={props.showTicks}
                showDate={false}
                highlightedIndices={highlightedIndices}
                disabledIndices={disabledIndices}
              />
            </div>
            <div className="device-status-info">
              {isActive(new Date()) ? <p style={{ color: ColorScheme.primary_green }}>Aktiivinen</p> : <p style={{ color: ColorScheme.light_grey }}>Inaktiivinen</p>}
              <p style={{ marginLeft: "0.5em" }}>Hinta nyt</p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ fontWeight: "900" }}>{dayPrices.current}</p><p style={{ color: ColorScheme.primary_green, marginLeft: "0.3em" }}>snt/kWh</p>
              </div>
            </div>
          </div>
          {renderNextActivity()}
        </div >
      </motion.nav>
    );
  }
}
