import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../ApiContext";
import ChangePassword from "./ChangePassword";
import Loading from "./Loading";
import { Toaster, toast } from "react-hot-toast";
import DeleteAccount from "./DeleteAccount";

export function Profile() {
    const { ApiCall } = useContext(ApiContext);

    const [profile, setProfile] = useState()

    useEffect(() => {
        if (profile) {
            return
        }
        ApiCall("GET", "user/profile").then((res) => {
            setProfile(res);
        }).catch((err) => {
            console.error(err)
        })
    }, [profile])

    function sendConfirmationEmail(event) {
        event.preventDefault()
        ApiCall("POST", "user/requestConfirm").then(() => {
            toast.success("Vahvistuslinkki lähetetty. Tarkista sähköpostisi.")
        }).catch((err) => {
            console.error(err)
            toast.error("Jotain meni pieleen. Yritä myöhemmin uudelleen.")
        })
    }

    return (
        <div className="main-content">
            <div style={{ display: "flex", flexDirection: "column", maxWidth: "500px", alignItems: "center" }} >
                <h2>Käyttäjätili</h2>
                <Toaster />
                <div className="input-container">
                    <h3>Sähköpostiosoite</h3>
                    {!profile && <Loading />}
                    {profile && <>
                        <p>{profile.email}</p>
                        {profile.emailConfirmed && <p style={{ marginBottom: "1em" }}>Sähköpostiosoitteesi on vahvistettu.</p>}
                        {!profile.emailConfirmed && <form onSubmit={sendConfirmationEmail}>
                            <label>Sähköpostiosoitettasi ei ole vahvistettu.</label>
                            <input type="submit" className="basic-button" value="Lähetä vahvistuslinkki" />
                        </form>}
                    </>}
                </div>
                <ChangePassword />
                <DeleteAccount />
            </div>
        </div>
    );
}
