import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Header from "./Header";
import { ApiProvider } from "../ApiContext";
const title = "Kajo Spotti - Optimoi sähkönkulutuksesi";
const shortTitle = "Kajo Spotti";
const description = "Optimoi pörssisähkön kulutuksesi ja säästä sähkölaskussa helposti ajastamalla kulutus halvimpaan aikaan";

function Root() {

    return (
        <ApiProvider>
             <div className="root-container">
            <Header />
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
            </Helmet>
            <Outlet />
            <div className="spacer"></div>
            <Footer />
            </div>
        </ApiProvider>
    )
}
export { title, shortTitle, description, Root }