import { useContext, useState } from "react"
import { ApiContext } from "../ApiContext"
import register from "../assets/images/register.svg"
import cheapestHours from "../assets/images/cheapest-hours.svg"
import safe from "../assets/images/safe.svg"
import timing from "../assets/images/timing.svg"

import "./Landing.css"
import { Link, useNavigate } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { shortTitle, description } from "./Root";
import ChargerSelection from "./ChargerSelection"

function GetStarted() {
    const { ApiCall, user } = useContext(ApiContext);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();
        ApiCall("POST", "user/welcome", { email: email })
            .then((res) => {
                console.log(res)
                if (res && res.new) {
                    navigate("/register/" + email);
                }
                else {
                    navigate("/login/" + email);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
    return (
        <div className="get-started-container">
            <form onSubmit={handleSubmit} className="get-started-form">
                <h3 className="get-started-title">Kokeile nyt ilmaiseksi</h3>
                <p className="get-started-sub-title"> Tarvitset vain sähköauton, pörssisähkösopimuksen ja internet-yhteydellisen latausaseman</p>
                <div className="get-started-input-container">
                    {!user && <input type="email" className="get-started-input" required={true} placeholder="Syötä sähköpostiosoitteesi" style={{ backgroundColor: "white" }}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }} />}
                    {!user && <input type="submit" value="Jatka" className="basic-button get-started-button" style={{ height: "50px" }} />}
                    {user && <button className="basic-button get-started-button" style={{ marginBottom: "80px" }}
                        onClick={e => {
                            e.preventDefault();
                            navigate("/device");
                        }}>Hallitse laitteitasi</button >}
                </div>
            </form >

        </div>
    )
}

function KeyPoint({ icon, title, description }) {
    return (
        <div className="key-point">
            <img src={icon} className="key-img" alt="kuvituskuva" />
            <div className="key-text">
                <h3 className="key-title">{title}</h3>
                <p className="key-description">{description}</p>
            </div>
        </div >
    )
}

function Landing() {
    // FIXME:Text are copypasta from welcome information email, move to some common place
    return (
        < div className="landing-container" >
            <div className="landing-header-content">
                <div className="landing-header">
                    <div className="landing-header-text" >
                        <h1 className="landing-title">{shortTitle}</h1>
                        <p className="landing-sub-title">{description}</p>
                        <div className="supported-chargers-container">
                            <HashLink smooth className="supported-chargers" to="/#supported-chargers">Katso lista tuetuista latausasemista</HashLink>
                        </div>
                    </div>
                </div>
                <GetStarted />
            </div>
            <div className="landing-content" >
                <div className="landing-information" >
                    <KeyPoint icon={register} title="Rekisteröi latausasemasi" description="Rekisteröi OCPP-kykyinen latausasemasi palveluumme helposti. Tämän jälkeen palvelussa voit ohjata latausasemaa joko manuaalisesti tai automaattisesti ajastettuna. Rekisteröinnin voi myös helposti purkaa tarvittaessa." />
                    <KeyPoint icon={cheapestHours} title="Ajoita lataus halvimmille tunneille" description="Valitse hintakatto ja aktiivisten tuntien lukumäärä. Tämän jälkeen latausasemasi lataa joka päivä automaattisesti pörssisähkön halvimmilla tunneilla, jos hinta alittaa hintakaton. Asetusten asettamisen jälkeen sinun ei tarvitse tehdä mitään." />
                    <KeyPoint icon={timing} title="Kattavat asetukset" description="Voit valita automaattiseen ajastukseen aktiivisuusajan vuorokaudesta, josta halvimmat tunnit lasketaan. Esimerkiksi voit asettaa latausaseman lataamaan vain öisin, jolloin latausasema lataa yön halvimpina tunteina, vaikka päivällä olisikin ollut halvempaa sinä vuorokautena." />
                    <KeyPoint icon={safe} title="Turvallista" description="Latausaseman ohjaus perustuu avoimeen OCPP-standardiin kryptografisesti salatuilla TLS-tietoliikenneyhteyksillä. Latausasemasi vastaa varsinaisesta latauksen suorittamisesta." />
                    <div id="supported-chargers" className="landing-information landing-additional-information">
                        <h4 className="additional-info-title">Tuetut latausasemat</h4>
                        <p className="additional-info-description">Palvelussa toimivat kaikki latausasemat, jotka toteuttavat OCPP 1.6J -standardin. Valtaosa markkinoilla olevista älylatausasemista toteuttaa OCPP 1.6J-standardin. Hyvin todennäköistä, että jos latausasemassasi on internet-yhteys (WiFi/ethernet), on se myös yhteensopiva palvelumme kanssa.</p>
                        <p className="additional-info-description">Seuraavavien latausasemien rekisteröinnille löytyy palvelustamme ohjeet ja nämä on validoitu yhteensopiviksi:</p>

                        <ChargerSelection selectedCharger={null} setSelectedCharger={(e) => console.log(e)} showOther={false} />
                        <p className="additional-info-description">Eikö latausasemaasi näy listassa? Ota yhteyttä sähköpostilla <Link to="mailto:info@kajosolutions.com?subject=Uuden latausaseman lisäys&Body=Hei,%0D%0Ahaluaisin tuen latausasemalleni">info@kajosolutions.com</Link> ja validoidaan yhdessä latausasemasi yhteensopivuus.</p>
                        <h4 className="additional-info-title">Open Charge Point Protocol (OCPP)</h4>
                        <p className="additional-info-description">Open Charge Point Protocol (OCPP) on avoin standardisoitu tiedonsiirtoprotokolla latausasemille. OCPP mahdollistaa latausaseman hallinnan turvallisesti keskitetystä palvelusta (Charging Station Management System, CSMS). </p>
                        <p className="additional-info-description">CSMS-palvelu voi esimerkiksi asettaa latausasemalle latausprofiilin, jolla lataamisen ajankohtaa voidaan hallita. Tällöin latauksesta vastaa kuitenkin aina paikallisesti latausasema ja CSMS-palvelusta saadaan vain korkean tason käyttösuunnitelma.</p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Landing;