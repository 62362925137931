import React, { useContext, useState } from "react";
import { ApiContext } from "../ApiContext";
import { motion } from "framer-motion";
import "./Device.css";
import { Toaster, toast } from "react-hot-toast";

function SetupNewDevice({ endSetup }) {
  const { ApiCall } = useContext(ApiContext);

  const [device_name, set_device_name] = useState("");
  const [device_mac, set_device_mac] = useState("");
  const [sent, set_sent] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    ApiCall("POST", "device", {
      mac: device_mac,
      displayName: device_name,
    })
      .then((res) => {
        console.log(res);
        set_sent(true);
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 400) {
          toast.error("Laitetta ei löytynyt. Tarkista tunnistekoodi.");
        } else {
          toast.error("Virhe uutta laitetta lisättäessä. Palauta laite tehdasasetuksiin ja kokeile uudestaan.");
        }
      });
  }

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "linear", duration: 0.2 }}
      className="device-setup-nav"
    >
      <Toaster />
      <div onClick={() => endSetup()} className="device-setup-container">
        <motion.nav
          initial={{ translateY: -1000 }}
          animate={{ translateY: 0 }}
          transition={{ ease: "easeOut", duration: 0.2 }}
          className="device-setup-nav"
        >
          <div onClick={(e) => e.stopPropagation()} className="popup-container input-container">
            <h2>Lisää uusi rele</h2>
            {!sent && (
              <form onSubmit={handleSubmit}>
                <p>
                  Rekisteröi uusi rele syöttämällä laitteen alustuksessa
                  esitetty tunnistekoodi. Jos tunnistekoodi on hukassa pitää
                  laite palauttaa tehdasasetuksiinsa ja alustus on tehtävä
                  uudestaan.
                </p>
                <label>Syötä releen tunniste</label>
                <input
                  type="text"
                  placeholder="Tunniste"
                  required={true}
                  onChange={(e) => set_device_mac(e.target.value)}
                />
                <label>Keksi releelle nimi</label>
                <input
                  type="text"
                  placeholder="Nimi"
                  required={true}
                  maxLength={256}
                  onChange={(e) => set_device_name(e.target.value)}
                />
                <input
                  type="submit"
                  value="Lisää rele"
                  className="basic-button"
                />
              </form>
            )}
            {sent && (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <h5>Rele lisätty onnistuneesti!</h5>
                <button onClick={() => endSetup()} className="basic-button">
                  Sulje
                </button>
              </div>
            )}
          </div>
        </motion.nav>
      </div>
    </motion.nav>
  );
}

export default SetupNewDevice;
