import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import PriceGraph from "./PriceGraph";
import Loading from "./Loading";
import { ApiContext } from "../ApiContext";

const VAT_PERCENTAGE = require("spot-automation-common").VAT_PERCENTAGE

function Prices() {
  const { prices } = useContext(ApiContext);
  const title = "Pörssisähkön spot-hinta";
  const description = "Pörssisähkön spot-hinta Suomessa tänään ja huomenna";

  let content = <></>
  if (prices) {
    const now = new Date();
    let nowIndex = 0;
    for (let i = 0; i < prices.length; i++) {
      const date = prices[i].date;
      if (
        now.getFullYear() === date.getFullYear() &&
        now.getMonth() === date.getMonth() &&
        now.getDate() === date.getDate() &&
        now.getHours() === date.getHours()
      ) {
        nowIndex = i;
      }
    }
    const nowPrice = prices[nowIndex].price;
    content =
      <>
        <p>Hinta nyt {nowPrice} snt/kWh (sis. ALV {VAT_PERCENTAGE} %)</p>
        <PriceGraph prices={prices} showTicks={true} showDate={true} showPoints={false} />
      </>
  } else {
    content = <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Kajo Solutions - {title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>

      <div className="main-content">
        <div style={{ display: "flex", flexDirection: "column", width: "85vw", maxWidth: "50em" }}>
          <h1>{title}</h1>
          <p>{description}</p>
          {content}
        </div>
      </div>
    </>
  )
}

export default Prices;
