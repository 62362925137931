import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.svg";
import { ReactComponent as Gear } from "../assets/icons/gear.svg";
import { ReactComponent as Connection } from "../assets/icons/connection.svg";
import DeviceStatus from "./DeviceStatus";
import DeviceLog from "./DeviceLog";
import placeholderPng from "../assets/images/kajokaara.jpeg";
import placeholder from "../assets/images/kajokaara.webp";
import DeviceSettings from "./DeviceSettings";
import DeviceOptions from "./DeviceOptions.js";
import { ApiContext } from "../ApiContext";
import Loading from "./Loading";
import "./Device.css";
import OcppOptions from "./OcppOptions";

function Config() {
  const { ApiCall } = useContext(ApiContext);
  const navigate = useNavigate();

  const { id } = useParams();
  const [device, set_device] = useState();
  const [config, set_config] = useState();
  const { value } = useParams();
  const [show_options, set_show_options] = useState(value === "options");
  const [show_ocpp, set_show_ocpp] = useState(value === "ocpp");

  useEffect(() => {
    ApiCall("GET", "device/" + id)
      .then((res) => {
        console.log(res);
        set_device(res);
      })
      .catch((err) => {
        console.error(err);
      });
    ApiCall("GET", "device/config/active/" + id)
      .then((res) => {
        console.log(res);
        set_config(res);
      })
      .catch((err) => {
        console.error(err);
        //Default values to use if config is not yet created
        set_config({
          maxPrice: 25,
          numberOfHours: 5,
          firstHour: 22,
          lastHour: 7,
          consecutive: false,
        });
      });
  }, [id, ApiCall]);

  function closeOptions() {
    set_show_options(false);
    set_show_ocpp(false);
  }
  if (!device || !config) {
    return <Loading />;
  } else {
    return (
      <div className="main-content">
        <div className="container">
          <div
            style={{
              width: "100%",
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <button onClick={() => navigate("/device")} className="circle-button">
                {/* shitty svg >:( */}
                <ArrowLeft />
              </button>

              <picture >
                <source srcSet={placeholder} />
                <img src={placeholderPng} className="device-header-image" alt="Laitteen kuva" />
              </picture>
              <h3 style={{ marginLeft: "1em", maxWidth: "50px" }}>
                {device && device.displayName}
              </h3>
            </div>
            <div style={{ display: "flex" }}>
              <button
                onClick={() => set_show_options(true)}
                className="circle-button"
                style={{ marginRight: "1em" }}
              >
                <Gear />
              </button>
              <button
                onClick={() => set_show_ocpp(true)}
                className="circle-button"
              >
                <Connection />
              </button>
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "0.5em" }}>
            <div className="device-info">
              <DeviceStatus config={config} showTicks={false} showTitle={true} />
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <DeviceSettings
              device={device}
              config={config}
              set_config={set_config}
            />
            <DeviceLog device={device} />
          </div>
          {show_options && (
            <DeviceOptions
              device={device}
              set_device={set_device}
              closeOptions={closeOptions}
            />
          )}
          {show_ocpp && (
            <OcppOptions
              device={device}
              set_device={set_device}
              closeOptions={closeOptions}
            />
          )}
        </div>
      </div >
    );
  }
}

export default Config;
