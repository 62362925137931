import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../ApiContext";
import ListDevice from "./ListDevice";
import SetupNewDevice from "./SetupNewDevice";
import SetupNewCharger from "./SetupNewCharger";
import Loading from "./Loading";
import "./Device.css"

function DeviceList() {
  const { ApiCall, todayPrices } = useContext(ApiContext);

  const [devices, set_devices] = useState();

  const [adding_new_relay, setAddingNewRelay] = useState(false);
  const [adding_new_charger, setAddingNewCharger] = useState(false);

  function loadDevices() {
    ApiCall("GET", "device").then((res) => {
      set_devices(res);
    }).catch((err) => {
      console.error(err)
    })
  }

  function endSetup() {
    setAddingNewRelay(false);
    setAddingNewCharger(false);
  }

  useEffect(() => {
    loadDevices();
  }, [adding_new_relay, adding_new_charger]);

  let content = <></>
  if (devices && todayPrices) {
    content = devices.length < 1 ? (
      <p style={{ marginTop: "2em" }}>
        Sinulla ei ole yhtään laitetta vielä
      </p>
    ) : (
      <div style={{ marginTop: 50, width: "100%" }}>
        {devices.map((device, index) => {
          return <ListDevice index={index} device={device} key={device.mac} />;
        })}
      </div>
    )
  }

  return (

    <div className="main-content">
      <div className="device-list">
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h2>Laitteet</h2>
          <button
            onClick={() => setAddingNewCharger(true)}
            className="basic-button"
            style={{ width: "auto", height: "34px", margin: "0px" }}
          >
            Lisää uusi
          </button>
          {/* TODO: Waiting for relay platform release
              <button
                onClick={() => setAddingNewRelay(true)}
                className="basic-button"
                style={{ marginLeft: "1em" }}
              >Rele</button>
              */}
        </div>
        {content}
      </div >

      {adding_new_relay && <SetupNewDevice endSetup={endSetup} />}
      {adding_new_charger && <SetupNewCharger endSetup={endSetup} />}
    </div>
  );
}

export default DeviceList;
