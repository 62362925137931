import React from "react";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { ColorScheme } from "../utils/ColorScheme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend
);

Tooltip.positioners.custom = function (elements, eventPosition) {
  if (elements.length > 0) {
    const element = elements[0].element
    return {
      x: element.x,
      y: element.y,
    }
  }
  else {
    return { x: 0, y: 0 }
  }
}

const PRICE_COLOR = ColorScheme.black;
const INVISIBLE_COLOR = "#ffffff00"
const NOW_COLOR = ColorScheme.black;
const DISABLED_COLOR = ColorScheme.light_grey_shade;
const HIGHLIGHT_COLOR = ColorScheme.primary_green_shade;

function options(data, showTicks, showDate) {
  return {
    responsive: true,
    animation: false,
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        ticks: {
          display: showTicks,
          callback: function (val, index) {
            // val is not value, index only?
            const date = data.labels[index];
            if (showDate && date.getHours() === 0) {
              return this.getLabelForValue(val).toLocaleString("fi-FI", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              });
            } else {
              return this.getLabelForValue(val).toLocaleString("fi-FI", {
                hour: "numeric",
                minute: "numeric",
              });
            }
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: showTicks,
        },
        border: {
          display: false,
        },
        grid: {
          display: showTicks,
          drawOnChartArea: true,
          drawTicks: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        position: "custom",
        animation: false,
        filter: function (tooltipItem) {
          return tooltipItem && tooltipItem.dataset && tooltipItem.dataset.type !== "bar";
        },
        callbacks: {
          title: function (context) {
            if (context.length > 0) {
              const index = context[0].dataIndex;
              const date = data.labels[index];
              return date.toLocaleString("fi-FI", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              });
            }
          },
        },
      },
    },
  };
}

function formatData(prices, highlightedIndices, disabledIndices, showPoints) {
  const maxPrice = Math.max(...prices.map(e => e.price));
  const minPrice = Math.min(...prices.map(e => e.price));
  const labels = prices.map((entry) => entry.date)
  return {
    labels,
    datasets: [{
      type: 'line',
      label: "Hinta (snt/kWh)",
      data: prices.map((entry) => entry.price),
      tension: 0.2,
      backgroundColor: PRICE_COLOR,
      borderColor: PRICE_COLOR,
      borderWidth: 4,
      pointBorderWidth: showPoints ? 5 : 0,
      pointHoverBorderWidth: showPoints ? 8 : 0
    },
    {
      type: 'bar',
      data: prices.map(() => maxPrice),
      base: Math.min(0, minPrice),
      inflateAmount: 0,
      borderSkipped: false,
      borderWidth: prices.map((val, index) => {
        const now = new Date()
        if (now.getDate() === val.date.getDate() && now.getHours() === val.date.getHours()) {
          return 1
        }
        return 0
      }),
      borderColor: NOW_COLOR,
      backgroundColor: prices.map((value, index) => {
        if (highlightedIndices.includes(index)) {
          return HIGHLIGHT_COLOR
        }
        else if (disabledIndices.includes(index)) {
          return DISABLED_COLOR
        }
        else {
          return INVISIBLE_COLOR
        }
      }),
    }]
  }
}

export default function PriceGraph({ prices, showTicks = true, showDate = true, highlightedIndices = [], disabledIndices = [], showPoints = false, }) {
  const data = formatData(prices, highlightedIndices, disabledIndices, showPoints)
  return (<Chart options={options(data, showTicks, showDate)} data={data} />)
}

